import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.0.24/index.vue';
import creditCurrentBillDetail from '@/lib/data-service/default/web_credit_creditDetailList'
import creditNotRefund from '@/lib/data-service/default/web_credit_creditNotRefund'
import changeOrderDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_changeDetail";
import buyer_dom_white_relaxPayAmount from '@/lib/data-service/flight/buyer_dom_white_relaxPayAmount'
export default {
  data() {
    return {
      detail: {},
      loading: false,
      amount: 0,
      radio: '',
      orderList: '',
      params: {
        "orderNo": "",
        //"passengerNoList": [],
        //"segmentNoList": [],
        "type": 0,
        bizOrderNo: ''
      },
      formData: {
        refundType: 2, // 还款类型：1.一键还款，2.主账单还款，3.子账单还款
        orderAmount: 0,
        orderNo: '',
        companyId: ''
      }
    }
  },
  components: {
    PaymentMethodSelector,
  },
  methods: {
    newPaySubmit() {
      const __this = this;
      /*__this.formData = Object.assign(__this.formData, {
        companyId: __this.detail.results[0].companyId
      })*/
      console.log(this.formData)
      __this.$refs.aPaymentMethodSelector.init({
        // situationParameter: {
        //   "100": {
        //     refundType: __this.$route.query.type || 1,//还款类型：1.一键还款，2.主账单还款，3.子账单还款
        //     orderAmount: __this.amount,//订单金额，用于计算可以用哪些支付方式支付
        //     billIds: __this.orderList,
        //   }
        // },
        getSituationParameter() {
          const o = {
            '100': __this.formData,
          /*{
              refundType: 1,//还款类型：1.一键还款，2.主账单还款，3.子账单还款
              orderAmount: 0.01,//订单金额，用于计算可以用哪些支付方式支付
              billIds: `${moment().format('YYYY-MM-DD HH:mm:ss')}`,
              businessContent:`xxx`,
            },*/
          }
          return o ;
          // let s = {
          //   '100': __this.formData
          // };
          // return s
        },
        before_pay_event_handler() {
          // let list = __this.detail.results.reduce((newArr, item) => {
          //   if (item.statuOrder) {
          //     newArr.push(item.id);
          //   }
          //   return newArr;
          // }, [])
          // if (list.length == 0) {
          //   __this.$message({ type: "warning", message: "请选择账单！" })
          //   return Promise.reject('error')
          // } else {
          //   __this.formData.billIds = list.join(',')
          //   return Promise.resolve('success')
          // }
        },

        //支付成功事件处理方法
        pay_successful_event_handler() {
          __this.$message({
            message: '还款成功',
            type: 'success',
            offset: 80
          });
          __this.$router.push({ name: 'air-ticket-admin-change-order-list' })
        },
        //支付失败事件处理方法
        pay_failed_event_handler() {
        },
      });
    },
    /*selectCheck() {
      this.formData.orderAmount = this.detail.results.reduce((num, item) => {
        if (item.statuOrder) {
          num += item.billAmount
        }
        return num;
      }, 0)
    },*/
    getDetail() {
      this.loading = true
      //creditNotRefund({billIds: this.params.bizOrderNo})
      buyer_dom_white_relaxPayAmount(this.params).then(res => {
        this.$nextTick(() => {
          this.detail = res
          this.loading = false
          this.formData.orderAmount = res.ableAmount
          this.formData.companyId = res.companyId
          this.formData.businessContent = res.businessContent
          this.newPaySubmit()
        })
      }).catch(() => {
        this.loading = false
      })

    }
  },
  activated() {
    this.formData.orderNo = this.$route.query.orderNo
    this.params.orderNo = this.$route.query.orderNo
    this.params.bizOrderNo = this.$route.query.bizOrderNo
    //this.params.passengerNoList = this.$route.query.passengerNoList ? JSON.parse(this.$route.query.passengerNoList) : []
    this.params.type = 15
    this.getDetail()
  }
}
