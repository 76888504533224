// buyer_dom_white_relaxPayAmount 轻松付还款金额-改签 http://192.168.0.31:8985/doc.html#/yinzhilv-flight/payment-controller/relaxPayAmountUsingPOST
const __request = require(`./__request/flight-admin/__request_contentType_json`);
const buyer_dom_white_relaxPayAmount = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix:'/buyer/dom/white/relaxPayAmount',
        data:data,
        // enable_error_alert: false // 临时隐藏
    }

    return __request(pParameter)
}
export default buyer_dom_white_relaxPayAmount
